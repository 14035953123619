import { useState, KeyboardEvent } from 'react';
import { DrawerEnum } from '../types/enum';

const useDrawer = (drawerAnchor: DrawerEnum, drawerSearchAnchor: DrawerEnum) => {
    const [drawer, setDrawer] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [drawerSearch, setDrawerSearch] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleToggle = (anchorType: DrawerEnum, isOpen: boolean) => (event: KeyboardEvent) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setDrawer({ ...drawer, [anchorType]: isOpen });
    };

    const handleSearchToggle = (anchorType: DrawerEnum, isOpen: boolean) => (event: KeyboardEvent) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setDrawerSearch({ ...drawerSearch, [anchorType]: isOpen });
    };

    const handleClose = () => {
        setDrawer({ ...drawer, [drawerAnchor]: false });
        setDrawerSearch({ ...drawerSearch, [drawerSearchAnchor]: false });
    };

    return { drawer, setDrawer, drawerSearch, setDrawerSearch, handleToggle, handleSearchToggle, handleClose };
};

export default useDrawer;
