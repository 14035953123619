import * as React from 'react';
import dynamic from 'next/dynamic';
import { useAppContext } from '../../../contexts/AppContext';
import { DrawerEnum } from '../../../types/enum';

const Drawer = dynamic<any>(() => import('../../Drawer').then((mod) => mod.Drawer), {
  ssr: false,
});
const DrawerContent = dynamic<any>(() => import('./DrawerContent').then((mod) => mod.DrawerContent), {
  ssr: false,
});
const NavigationSearchResults = dynamic<any>(
  () => import('../search/NavigationSearchResults').then((mod) => mod.NavigationSearchResults),
  {
    ssr: false,
  }
);

interface DrawerWrapperProps {
  drawer: any;
  handleToggle: any;
  drawerSearch: any;
  handleSearchToggle: any;
  children?: React.ReactNode; // Add children to the props
}

export const DrawerWrapper: React.FC<DrawerWrapperProps> = ({
  drawer,
  handleToggle,
  drawerSearch,
  handleSearchToggle,
  children, // Destructure children from props
}: DrawerWrapperProps) => {
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const drawerSearchAnchor: DrawerEnum =
    componentOptions.component_navigation_bar_search_drawer_anchor || DrawerEnum.RIGHT;
  const drawerAnchor: DrawerEnum = componentOptions.component_navigation_bar_drawer_anchor || DrawerEnum.LEFT;

  return (
    <>  
      <Drawer state={drawer} anchor={drawerAnchor} onToggle={handleToggle}>
        <DrawerContent>
          {children}
        </DrawerContent>
      </Drawer>
      <Drawer state={drawerSearch} anchor={drawerSearchAnchor} onToggle={handleSearchToggle}>
        <NavigationSearchResults />
      </Drawer>
    </>
  );
};
