import React, { useEffect } from 'react';

function useResizeObserver(refs: React.RefObject<HTMLDivElement>[], setMenuSliceAmount: React.Dispatch<React.SetStateAction<number>>, theme: any) {
  useEffect(() => {
    const observer: ResizeObserver = new ResizeObserver(() => {
      const leftWidth = refs[0].current?.offsetWidth || 0;
      const centerWidth = refs[1].current?.offsetWidth || 0;
      const rightWidth = refs[2].current?.offsetWidth || 0;
      const usedWidth =
        leftWidth +
        centerWidth +
        rightWidth +
        parseInt(theme.spacing(4).replace('px ', ''), 10);
      if (usedWidth > window?.innerWidth) {
        setMenuSliceAmount((previousState: number) => previousState - 1);
      }
    });

    if (observer) {
      refs.forEach((ref: any) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, refs);
}

export default useResizeObserver;
