import * as React from 'react';
import dynamic from 'next/dynamic';
import { useAppContext } from '../../../contexts/AppContext';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const LastPlayedMenu = dynamic<any>(() => import('../../LastPlayedMenu').then((mod) => mod.LastPlayedMenu), {
  ssr: false,
});

const LanguageSelectMenu = dynamic<any>(() => import('../../LanguageSelectMenu').then((mod) => mod.LanguageSelectMenu), {
  ssr: false,
});

const SearchField = dynamic<any>(() => import('../../SearchField').then((mod) => mod.SearchField), {
  ssr: false,
});

const SearchFieldStyled = styled(SearchField)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing('auto', 0, 'auto ', 1),
}));

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.theme_component_navigation_bar_icon_color || theme.palette.primary.contrastText,
}));

interface DrawerWrapperProps {
  handleClose: any;
  drawerSearch: any;
  drawerSearchAnchor: any;
  setDrawerSearch: React.Dispatch<React.SetStateAction<any>>;
  children?: React.ReactNode;
}

export const ToolbarRightContainer: React.FC<DrawerWrapperProps> = ({
  handleClose,
  setDrawerSearch,
  drawerSearch,
  drawerSearchAnchor,
  children,
}: DrawerWrapperProps) => {
  const theme = useTheme();
  const appContext = useAppContext();
  const router = useRouter();
  const { componentOptions } = appContext.useConfig();
  const locales = router?.locales || [];
  const [disableLiveSearch, setDisableLiveSearch] = React.useState(true);

  const lastPlayedButtonDisabled = componentOptions.component_navigation_bar_last_played_button_disabled;
  const languageMenuButtonDisabled =
  locales.length > 1 ? componentOptions.component_navigation_bar_language_menu_button_disabled : true;
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  React.useEffect(() => {
    handleClose();

    if (router.pathname === '/search') {
      setDisableLiveSearch(false);
    } else {
      setDisableLiveSearch(true);
    }
  }, [router.asPath]);

  return (
    <>  
      {!lastPlayedButtonDisabled && (
        <LastPlayedMenu
          size={sm ? 'medium' : 'small'}
          edge="start"
          variant={sm ? 'standard' : 'dot'}
          overlap={sm ? 'rectangular' : 'circular'}
        />
      )}
      {!languageMenuButtonDisabled && (
        <LanguageSelectMenu
          edge={lastPlayedButtonDisabled ? 'start' : undefined}
          size={sm ? 'medium' : 'small'}
        />
      )}
      {!componentOptions.component_header_search_hidden && (md ? (
        <SearchFieldStyled disableLiveSearch={disableLiveSearch} />
      ) : (
        <IconButton
          edge="end"
          size={sm ? 'medium' : 'small'}
          onClick={() => setDrawerSearch({ ...drawerSearch, [drawerSearchAnchor]: true })}
          aria-label="search"
        >
          <SearchIconStyled />
        </IconButton>
      ))}
      {children}
    </>
  );
};
